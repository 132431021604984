@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "bootswatch";
@import "lib/all.css";
@import "~@fullcalendar/bootstrap/main.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "@arcgis/core/assets/esri/themes/light/main.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.svg');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/Myriad-Pro.ttf');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Myriad Pro Semi Condensed Light';
  src: url('../fonts/Myriad-Pro-Light-SemiCondensed.ttf');
}

@font-face {
  font-family: 'Myriad Pro Semi Condensed';
  src: url('../fonts/MyriadPro-BlackSemiCn.otf');
}

// The free font awesome font will not load in any other weight
.fa, .fas {
  font-weight: 900 !important;

  .danger {
    color: $danger;
  }
}

html, body {
  height: 100%;
  background-color: rgb(253, 253, 253)
}

#root {
  height: 100%;
}

h1 {
  color: $secondary;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  font-family: "Myriad Pro";
}

h2, h3, h4, h5 {
  color: $gray-600;
  &.secondary {
    color: $secondary;
  }
}

h2.card-header, .card-header h2 {
  font-size: 1.5em;
}

div, span, p {
  &.info {
    color: $info;
  }
}

a {
  
  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    color: $gray-700;
    pointer-events: none;
  }
}

.btn {
  &.btn-link {
    text-transform: none;

    &[disabled] {
      color: $gray-700;
      pointer-events: none;
      background-color: transparent;
    }
  }

  &.readonly {
    pointer-events: none;
  }
  
  &.btn-icon {
    @extend .py-0;
  }

  &-secondary {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 120, 44, 0.5);
    }
  }
  &-danger {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
    }
  }

  &-md {
    padding: 0.85em;
    line-height: 0.85em;
  }

  &-nav {
    text-transform: capitalize;

    color: $primary;

    .active {
      font-weight: bold;
    }

    .danger {
      color: $danger;
    }

    &:hover {
      color: $primary;
      box-shadow: none !important;
    }
  }
}

.btn-group {
  .btn,
  .btn + .btn {
    box-shadow: none;
  }
  border-radius: 0.25em;
}

.dt-buttons.btn-group {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}

.btn.disabled {
  color: $black;
  background-color: $gray-500
}

.btn-group {

  .btn:not(.btn-nav):focus,
  .btn:not(.btn-nav):hover {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4) !important;
  }
}

.fc {
  padding: 1.5em 0;

  &-dayGridMonth-view,
  &-dayGridWeek-view,
  &-dayGridDay-view,
  &-view-container {
    background-color: $white;
    height: calc(100vh - 185px);
  }

  &-dayGridMonth-view,
  &-dayGridWeek-view,
  &-dayGridDay-view {
    .table-bordered,
    .fc-body {
      height: 100%;
    }
    
    .fc-day-grid-container {
      height: 100% !important;
      overflow: auto !important;
    }
  }

  &-dayGridMonth-view {
    .fc-day-grid-container {
      .fc-day-grid {
        height: 100%;
      }
    }

    .fc-week {
      max-height: 7.5em;
    }

    .fc-dayGrid-view .fc-body .fc-row {
      height: 15vh !important;
    }
    
    .fc-content-skeleton {
      height: 100%;
      table {
        height: 100%;
      }
    }
  }
  
  &-dayGridWeek-view {
    .fc-day-grid-container {
      .fc-day-grid {
        height: 100%;

        .fc-row.fc-week {
          height: 100% !important;
        }
      }
    }

    .fc-title {
      white-space: normal;
    }

    .fc-dayGrid-view .fc-body .fc-row {
      height: 100% !important;
    }
  }

  &-dayGridDay-view {
    .fc-content-skeleton {
      height: 100%;
    }
  }

  .btn, .btn + .btn {
    box-shadow: none;
  }

  .fc-today-button:not([disabled]),
  .btn-group {
    border-radius: 0.25em;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  }

  &-popover {
    max-height: 80vh;

    .fc-body {
      overflow: auto;
    }
  }

  &-bootstrap .fc-more-cell {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      a.fc-more {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-sm;
        font-size: smaller;
        color: white;
        padding: 0 0.5em;
        margin: 0.25em 0 auto;
        text-transform: initial;
      }
    }
  }

  &-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    font-size: 0.85em;
  }
}

fieldset legend {
  font-size: 1em;
}

.modal-title {
  font-size: large;
  font-weight: bold;
}

.modal-body {
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
}

#banner {
  height: $navbar-height;
  padding: 0.5em 1em;
  box-shadow: none;

  .navbar-brand {
    color: $white;
    margin-right: 0;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;

    img {
      height: 76px;
    }
  }
}

#navbar-menu-items {
  background-color: $gray;
  padding: 0;
  position: relative;
  align-items: flex-start;

  ul.navbar-nav {
    padding: 0;
    flex-direction: row;

    li {
      display: block;

      a {
        text-transform: uppercase;
        color: $dark;
        font-weight: bold;
        padding: 15px 9.95px 16px;
        font-size: 18px;
      }
    }

    .dropdown {
      position: relative;

      &.show {
        .dropdown-toggle {
          background-color: $white;
          color: $secondary;
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      padding: 1em 0;
      border-radius: 0 0 2px 2px;

      a {
        font-weight: normal;
        text-transform: none;
        padding: 0 1em;
        font-size: 1em;
        color: $black;

        &:hover {
          background-color: $secondary;
          color: $white;
        }
      }
    }
  }
}

.navbar-toggler-icon {
  background-image: $navbar-dark-toggler-icon-bg;
}

.nav-scroller {
  position: relative;
  z-index: 3;
  height: 2.75rem;
  overflow-y: hidden;

  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

.nav-underline {

  .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .875rem;
    color: #6c757d;
  
    &:hover {
      color: #007bff;
    }
  }

  .active {
    font-weight: 500;
    color: #343a40;
  }
}

.calendar-container {
  
  .fc-today {
    .fc-day-number {
      color: $white;
    }
  }

  .fc.fc-bootstrap {
    padding-top: 0;
  }

  .btn:not(a) {
    padding: 0.65em;
    line-height: 0.65em;
    background-color: $dark;
    font-size: large;
    font-weight: bold;

    &:hover {
      opacity: 0.75;
    }
  }
  
  .fc-today-button {
    background-color: $secondary;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em;

    .btn-group .fa {
      line-height: 0;
    }
  }
  
  .badge {
    font-weight: normal;
  }
}

.badge.badge-secondary {
  background-color: $secondary;
}
.badge.badge-warning {
  color: #212121;
}

.dashboard-row-small {
  height: 170px;
  margin-bottom: 10px;
  
  & > div {
    height: 100%;
  }
}

.dashboard-row-big {
  margin-bottom: 10px;
  
  & > div {
    height: 350px;
  }
}

.tile {
  width: 100%;
  height: 100%;
  position: relative;

  &-title {
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.4em;
    line-height: 1em;

    &-container {
      position: absolute;
      bottom: 1.25em;
      padding: 0px 1.25em;
      background-color: #0B3B61;
    }
  }

  &-overlay-menu {
    width: 100%;
    height: 100%;
    position: relative;
    height: calc(100% - 41px);
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding: 0;
    
      li a {
        width: auto;
        color: #fff;
        display: block;
        padding: 2px 7px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        font-weight: 350;
        font-size: .85rem;
      
        &:hover {
          text-decoration: none;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.dnr-logo {
  position: absolute;
  top: .5em;
  width: 200px;
}

.theme-switcher {
  position: fixed;
  bottom: 3em;
  right: 3em;
}

.map-switcher {
  position: fixed;
  bottom: 3em;
  right: 12em;
}

.media-left {
  width: 25%;
  margin-right: 1em;
}

.media-left .media-object {
  width: 100%;
}

.menu-subnav {
  list-style: none;

  li a {
    font-weight: normal;
    font-size: 16px;
    text-transform: capitalize;
    color: $dark;
  }
}

.dropdown ul {
  position: absolute;
  top: 50px;
  display: none;
  background-color: $white;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  width: 125%;
  z-index: 10000;
}

#navbar-secondary .dropdown:hover > a {
  color: $secondary;
  background-color: $white;
  border-bottom: 1px solid $gray-200;
}

.search-container {
  position: relative;
}

.search-container img {
  position: absolute;
  top: 16px;
  right: 10px;
}

.esriMapRelative{
  position: relative;
}

#map-menu{
  position: static;

  .dropdown-toggle{
    color:white;
    font-size: 12px;
    background-color: rgb(123, 123, 123);
    padding: .2em .6em .2em .2em;
    border-radius: 25px;
    position: absolute;
    width: auto;
    right: 10px;
    top: 10px;
    opacity: .65;
  }
}

.btn.dropdown-toggle::before{
  font-size: 2em;
  margin-left: unset;
  margin-right: 0.3rem;
}

form.burn-permit {
  .esri-ui-top-left {
    left: 50px;
  }
}

.esri-ui {
  .esri-expand--auto {
    .esri-expand {
      &__panel {
        .esri-widget--button {
          background-color: unset;
        }
      }
      &__container--expanded {
        background-color: transparent;
        position: relative;
        .esri-expand__panel {
          padding: 0;
        }
        .esri-expand__content {
          margin: 0;
        }
      }
      &__mask {
        display: none;
      }
    }
  }
}

.esri-view-width-xsmall {
  .esri-ui-bottom-right {
    .esri-expand--auto {
      .esri-expand {
        &__container--expanded {
          width: 100%;
          position: relative;
          .esri-expand__panel {
            padding: 0;
          }
          .esri-expand__content {
            margin: 0;
          }
        }
        &__mask {
          display: none;
        }
      }
    }

  }
}

.esri-widget--button,
button.draw-button {
  background-color: white;
}

.esri-widget,
.draw-button {
  border-radius: 0.25em;
}

.esri-ui-top-left {
  .esri-component {
    margin: 0.25em 0;

    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
}

.map-container {
  transition: height 250ms ease;
}

.toolbarTool {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 5px;
}

#map-toolbar-launcher {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 13px;
  top: 10px;
  font-size: 20px;
  background: #aaaaaadd;
  border-radius: 21px;
  line-height: 42px;
  text-align: center;
  vertical-align: middle;
  z-index: 9999;
}

#map-toolbar {
  position: absolute;
  border-radius: 3px;
  left: 60px;
  top: 10px;
  width: 50vw;
  background: #aaaaaaaa;
  padding: 5px;
  display: none;
  z-index: 9999;
  flex-flow: row nowrap;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.dt-buttons {

  &.btn-group {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);

    .btn {
      box-shadow: none;
  
      &:not(:first-child),
      &:not(:last-child) {
        border-radius: unset;
      }
      
      &.btn-secondary {
        background-color: $light;
        color: $body-color;
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
  
        &:nth-child(2) {
          border-radius: 0;
        }
      }
    }
  }
}

.custom-select.custom-select-sm {
  height: auto;
  font-size: 1.1rem;
}

select.form-control {
  height: 2.5em;

  &:disabled,
  &[readonly] {
    background-image: none;
  }
}

.esri-view {
  height: 100%;
}

#outer-container {
  // Typically we want to avoid `!important` as much as possible,
  // however the FullScreen widget writes styles directly on the element
  // which takes presedence over even the most specific scoped selector like this
  div#base-container:fullscreen { 
    height: 100%;
    width: 100%;
    position: absolute !important; 
  }
}

.tlVis{
  visibility: hidden;
}

.profile-container .card-header,
.fc-day-grid-event,
.form-check-label,
.form-radio-label {
  cursor: pointer;
}

.profile-container {
  .nav-tabs {
    .nav-item {
      cursor: pointer;
    }
  }
}

.fade:not(.modal-backdrop) {
  height: 0;
  visibility: hidden;
  overflow: hidden;

  &.show {
    height: auto;
    visibility: visible;
    overflow: visible;
  }
}

.align-items-top-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row > div {

  .form-group {

    &.compact-select-group {
      select {
        padding: 0;
        height: 2.5em;
      }
    }
  }
}

.form-control {
  color: $gray-900;
  &:invalid,
  &.is-invalid {
    padding-right: 0;
  }
}

.compact-select-group {
  select {
    padding: 0;
    height: 2.5em;
  }
}

input.form-control {
  height: 2.5em;
  padding: 0.25em 0;
}

[readonly] {
  color: $gray-575;
  cursor: not-allowed;
}

#graphics-tools-container {
  position: absolute;
  top: 1em;
  right: 0.5em;

  .draw-button {
    padding: 0.5em;
    border: none;
    margin: 0 0.25em;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    color: #323232;

    &.active{
      background:#f0f0f0;
    }
  }
}

h1, h2, h3, h4, h5,
label {
  button.btn {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.toast {
  background-color: rgba(255, 255, 255, 0.975);
  &-body {
    white-space: pre-wrap;
  }
}

.permit-table-card-header  *:last-child{
  margin-left: auto;
}

.permit-cart-summary .row:nth-child(odd) {
  background-color: rgb(248, 248, 248);
}

.burn-request-search-container,
.burn-permit-search-container {
  label {
    margin: 0;
  }
  button {
    padding: 0.5em;
  }
}

.burn-permit-header {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.75em;
    display: inline;
  }
}

.requiredFieldsMissing{
  margin-left:5px;
  color:red;
}

.application-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  background: rgb(245, 245, 245);
  
  .form-check-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    text-overflow: clip;
  }

  .nav-dot {
    position: relative;
    margin: 0;
  }

  span.nav {
    margin: 0 0.5em;
  }

  &.btn-group > .btn-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.5em;
    white-space: pre;

    &.active {
      background: $primary;
      color: #fff;
      border-radius: 3px;
      font-weight: normal;
      
      p { 
        font-weight: normal;
      }
      .fas, span {
        color: #fff;
      }
    }

    &.danger {
      background: $danger;
      color: #fff;
    }
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.application-instructions {
  .modal-dialog {
    max-width: 100%;
    width: 75%;
  }
  .fas {
    font-size: 2em;
    &.fa-arrow-circle-right {
      font-size: 1.5em;
      color: $warning,
    }
  }
}

.burn-permit-section {

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.9);
    &.disabled {
      color: $gray-600;
      cursor: not-allowed;
    }
  }
  h3 {
    display: inline-block;
    color: $gray-700;
    font-weight: normal;
    font-size: 1.5rem;
  }
  &.disabled {
    color: $gray-600;
    cursor: not-allowed;
  }
  &.disabled input {
    pointer-events: none;
  }
}

.disabled {
  color: $gray;
  input, select {
    pointer-events: none;
  }
}

.info-button {
  width: 1.5em;
  height: 1.5em;
  background: $gray;
  -moz-border-radius: .75em;
  -webkit-border-radius: .75em;
  border-radius: .75em;
  padding: .25em;
  font-size: .75em;
  color: $gray-900;
  cursor: pointer;

  &.open {
    box-shadow: 0 0 7.5px -2.5px $gray-600;
  }
}

.broadcast-natural-fuels-card {
  &.disabled {
    display: none;
  }
}

.short-error-message .invalid-feedback {
  width: 4em;
}

table.dataTable {
  font-size: 0.9em;
  tbody {
    tr:not(.no-click) {
      cursor: pointer;
    }
  }
  &.table th {
    white-space: nowrap;
  }
  &.table th,
  &.table td {
    padding: 0.5em;
  }
}

.dataTables {
  &_info {
    display: inline-block;
  }
  &_paginate {
    float: right;
  }
  &_wrapper {
    .table-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .dt-buttons {
        @extend .btn-group;
        .dt-button-collection {
          padding: 0;
          .dt-button {
            width: 100%;
            text-align: left;
            padding: 0.25em 1em;
            &.active {
              background-color: $primary;
              color: $white;
            }
          }
        }
        .dt-button {
          @extend .btn;
          @extend .btn-sm;
          text-align: left;
          color: $gray-900;
          &.buttons-columnVisibility {
            text-transform: capitalize;
          }

          &.dropdown-toggle::before {
            display: none;
          }
          .dt-down-arrow {
            display: none;
          }
          &-background {
            height: 0;
            width: 0;
          }
        }
      }
    }
  }
  &_length select {
    padding: 0.5em 1em 0.5em 0.25em;
  }
}

.popover {
  &-med-width {
    max-width: 30em;
  }
  &-header {
    background-color: #FCFCFC;
  }
}

.loading-animation {
  display: inline-block;
  img {
    width: 3em;
  }
}

.spinner-border {
  display: block;
  margin: 0 auto;
}

.agency-card-margin {
  margin: 5px;
}

.toast {
  button {
    font-size: 1.5em;
    height: 1em;
  }
}

.general-messages,
.offline-messages,
.error-messages {
  pointer-events: none;
}

.general-messages,
.offline-messages,
.error-messages,
.success-toast,
.reload-toast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 10000;
  .toast {
    max-width: 25em;
    pointer-events: all;
    flex-basis: auto;
  }
}

.success-toast {
  bottom: 0;
  width: 15em;
  span {
    margin-right: 0.5em;
  }
}

.reload-toast {
  width: 28em;
  max-width: 100%;

  button {
    font-size: 1.25em;
    line-height: 1.25;
  }
}

.audit-data {
  color: $gray-575;
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.toast-header {
  strong {
    width: 100%;
  }

  button {
    span {
      font-size: 0.65em;
      vertical-align: top;
    }
  }
}

.progress {
  height: 0.5rem;
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;

  .progress-bar {
    width: 100%;
    z-index: 1030;
    height: inherit;
  }
}

.download-progress { 
  width: 50%;
  top: 50%;
  right: 15%;
  left: unset;
  transform: translateY(-50%);
}

.download-success {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
}

.not-found {
  .card-actions {
    display: flex;
    justify-content: space-between;
  }
}

.grey-text {
  color: #bbbbbb;
}
.inline-text { 
  display: inline;
}
.condition-error-text {
  font-size: 0.85em;
  color: #f44336;
}

input.inline-text-input {
  width: 10em;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
  height: 1.5em;
  padding: 0px;
  &:disabled {
    color: #444444 !important;
    border: none !important;
  }
}

textarea.textarea-condition {
  color: #000000;
  &:disabled { 
    color: #444444;
    border: none;
  }
}

.editor-condition-outer {
  padding: 6px 5px;
}

.editor-condition-toolbar {
  border-color: rgb(241, 241, 241);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
} 

.editor-condition-editor {
  border-color: rgb(241, 241, 241);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
} 

input.number-condition {
  max-width: 4em;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
  height: 1.5em;
  padding: 0px;
  &:disabled {
    color: #444444;
    border: none;
  }
}

.region-row {
  font-weight: bold;
}

.autocomplete-menu {
  z-index    : 3;
  position  : absolute;
  overflow  : auto;
  max-height : 50vh;
  background-color: $white;
  box-shadow: 0 0 10px -5px $black;
  div {
    padding   : 0.25em 0.5em;
  }
}

.legal-desc {
  .invalid-feedback {
    white-space: normal;
  }
}

.cond-invalid-feedback {
  display: block;
  white-space: pre;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}

.radio-invalid-feedback {
  display: block;
  white-space: pre;
  cursor: default;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}

.dropzone {
  height: 10em;
  width: 100%;
  background-color: #fff;
  border: 2px dashed $primary;
  border-radius: .25em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;

  &:hover {
    border: 2px dashed $secondary;
  }

  .file-input {
    display: none;
  }

  &.disabled { 
    border-color: $gray-600 !important;
    background-color: $gray-500 !important;

    span {
      color: black;
    }
  }
}

.download-document-row {
  background-color: $gray-75;
  border: 1px solid $gray-200;
  height: 100%;

  .card-body {
    justify-content: space-between;
    display: flex;
    flex-flow: column;
  }

  .card-text {
    word-break: break-all;
    font-size: 0.85em;
  }
}

.ReportViewerHeader,
.ReportViewerContent {
  .row {
    width: 100%;
  }
}


.ReportViewerContent {
  max-height: calc(100vh - 366px);
  overflow: auto;

  table {
  
    th:not(:first-child),
    td:not(:first-child) {
      text-align: right;
      padding-right: 1em;
    }
  
    tbody tr {
      cursor: default !important;
      
      &:first-child {
        display: none;
      }
    }
  }
}

.ReportViewerPager {
  .btn-toolbar {
    justify-content: space-between;
    .btn-group {
      height: 100%;
    }
    .PagerNumbers div {
      max-width: 6em;
    }
  }
  
  .btn.dropdown-toggle::before {
    display: none;
  }
}

.DayPicker:first-child .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.DayPicker:first-child .DayPicker-Day {
  border-radius: 0 !important;
}
.DayPicker:first-child .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.DayPicker:first-child .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.DayPicker:first-child .DayPickerInput-Overlay {
  width: 550px;
}
.DayPicker:last-child .DayPickerInput-Overlay {
  margin-left: -198px;
}

.DayPickerInput {
  width: 44%;
  input {
    @extend .form-control;
  }
}

.DayPicker-Caption select {
  width: 48%;
}

#request-list-table tbody td {
  padding-right: 2em;
  padding-left: 0;
}

.merge-container {
  .form-check-input {
    transform: translate(0%, 50%);
    top: 50%;
    left: 25%;
    margin: 0;
  }
  .form-control {
    height: 2em;
  }
  .form-group {
    margin-bottom: 0.75em;
  }
  label {
    cursor: pointer;
  }
}

.Addresses-group,
.Phones-group,
.Permits-group {
  .row div[class^=col-] {
    display: flex;
    &:first-child {
      justify-content: space-evenly;
      font-size: 1.5em;
      div {
        font-size: 1rem;
      }
    }
  }
  i:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: $secondary;
  }
}

.Addresses-group,
.Phones-group {
  .row div[class^=col-] {
    align-items: center;
  }
}

.Permits-group {
  .row div {
    &:first-child {
      align-items: center;
    }
    &:last-child {
      flex-flow: column;
    }
  }
}

.Addresses-group {
  i:before {
    content: '\f2bb';
  }
  .row div[class^=col-] {
    &:last-child {
      overflow: auto;
      white-space: pre;
    }
  }
}

.Phones-group {
  i:before {
    content: '\f87b';
  }
}

.Permits-group {
  i:before {
    content: '\f15c';
  }
}

.calendar-event-detail tbody td:first-child {
  white-space: nowrap;
}

.post-burn {
  h4 {
    cursor: pointer;

    &.text-underline {
      text-decoration: underline;
    }
  }

  .form-group {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-bottom: 1rem;

    label {
      order: 1;
      margin-bottom: 0;
    }
    .invalid-feedback {
      order: 2;
    }
    .form-control {
      order: 3;
    }
  }
}

.cash-check-form {
  .form-group {
    label { cursor: pointer; }
  }
}

.alert-preferences {
  .is-invalid::before,
  .invalid-feedback {
    color: $warning;
  }
  .is-invalid {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
    &:focus {
      border-color: $warning;
      box-shadow: 0 0 0 0.2rem rgba(208, 158, 62, 0.25);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.network-status {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  padding: 0;

  .fa {
    padding: 0.5em 0.4em;
    font-size: 1.25em;
    height: 2em;
    width: 2em;
    border-radius: 2em;
  }
}

.alert {
  &-info,
  &-primary,
  &-danger {
    color: $white;
  }
  &-warning {
    background-color: #f6ecd8;
    color: $warning;
  }
}

/* Phone / Tablet layouts */
@media (max-width: 768px) {
  #root {
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.35em;
    }
    h3 {
      font-size: 1.15em;
    }
    h4, h5 {
      font-size: 1em;
    }
  }

  .esri-ui-corner {
    width: 50%;
    max-width: 50%;
  }

  .esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
    position: absolute;
    width: 100%;
  }

  /* Until we get the search wired up, let's hide it on phone layouts */
  .search-container {
    display: none;
  }

  .tile {
    &-title-container{
      position: relative;
      bottom: 0;
      padding: 0.5em 1em;
    }

    &-overlay-menu {

      ul {
        list-style-type: none;
        padding: 0;
    
        li a {
          width: auto;
          color: #996007;
          display: block;
          padding: 0.125em 0.5em;
          font-weight: bold;
          font-size: .85rem;
        }
      }
    }
  }

  #banner .navbar-brand img {
    height: 57px;
  }

  .fc {
    &-today {
      .fc-day-number {
        color: $white;
      }
    }
    &-view-container {
      height: calc(100vh - 188px) !important;
    }

    &-bootstrap {
      padding: 0.5em 0;
    }

    a.fc-more {
      font-size: 0.75em;
      cursor: pointer;
    }
    
    &-toolbar {
      flex-wrap: wrap;
      flex-direction: row;
  
      .fc-center {
        order: -1;
        flex: 1 0 100%;
        text-align: center;
      }
    }

    &-dayGrid-view .fc-body .fc-row {
      height: 6.15em;
    }

    &-day-grid-container {
      height: calc(100vh - 242px) !important;

      .fc-day-grid {
        height: 100%;
      }

      .fc-row.fc-week {
        height: 100% !important;
      }
    }
  }
  
  .table-buttons div.dt-buttons {
    width: auto;
  }

  .table-buttons,
  .search-warning {
    order: -1;
  }

  .table-buttons {
    padding-top: 0.5em;
  }
  
  .application-instructions {
    .modal-dialog {
      max-width: 100%;
      width: calc(100vw - 1rem);
      margin: auto;
    }
    .modal-body {
      max-height: calc(100vh - 6rem);
    }
  }

  .application-nav {
  
    span.nav {
      margin: 0 0.25em;
    }
  }

  .card-body {
    padding: 0.75em;
  }
  .p-4 {
    padding: 1em !important;
  }
}

/* Tablet and bigger layouts */
@media (min-width: 769px) {
  #root {
    h1 {
      font-size: 1.75em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.25em;
    }
    h4, h5 {
      font-size: 1em;
    }
  }
  
  .tile {
    background-color: #0B3B61;
    &-overlay-menu {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: #996007;
      -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      -ms-transition: all 0.5s linear;
      transition: all 0.5s linear;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: linear;
      transition-delay: 0s;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
  
      &:hover {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
      }
    }
  }

  .form-inline select.form-control {
    width: 100%;
  }

  .permit-condition-chk {
    max-width: 2em;
  }
  .applicationNextSteps {
    width: 75%;
  }
}

/* Tablet and smaller layouts */
@media (max-width: 991.98px) {
  
  #navbar-menu-items {
    &.offcanvas-collapse {
      position: fixed;
      top: $navbar-height; 
      bottom: 0;
      left: 100%;
      width: 100%;
      padding: 0;
      overflow-y: auto;
      visibility: hidden;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      transition-property: left, visibility;
      z-index: 5;

      &.open {
        left: 0;
        visibility: visible;
    
        ul.navbar-nav {
          flex-direction: column;
          width: 100%;
        }
    
        .dropdown-menu.show {
          width: 95%;
          margin: 0 auto;
          position: relative !important;
          transform: none !important;
          background-color: transparent;
          box-shadow: none;
          padding: 0;

          .nav-link {
            color: $secondary;
            font-weight: bold;
            padding: 0.5em 1em;
            border-top: 1px solid #eee;
            &:last-child {
              border-bottom: 1px solid #eee;
            }
          }
        }
      }
    }

    .container {
      flex-flow: column;
      align-items: flex-start;
      padding: 0;
      background: $white;
    }
  }

  .rbc-toolbar {
    flex-direction: column-reverse;
    
    span {
      margin: 0.5em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .progess-bar {
    top: 7.7em;
  }

  .table-buttons div.dt-buttons {
    width: auto;
  }

  .network-status {
    right: 4em;
    bottom: 1em;
  }
  .applicationNextSteps {
    .modal-body {
      padding: 1em 0.75em;
    }
  }
  .error-messages {
    width: 100vw;
    padding: 1em;
    .toast {
      flex-basis: auto;
      max-width: 100vw;
    }
  }
}

/* Desktop only layouts */
@media (min-width: 991.98px) {

  .reload-toast {
    margin-top: 1em;
  }
}

.post-burn-ul {
  margin-bottom: 0;
}

.table-txtedit {
  padding-top: 0;
}

.table-p {
  white-space: pre-line;
}

.rule-table-header {
  div {
    font-weight: bold;
  }
}
.rule-table-row {
  border-bottom: 1px grey solid;
}

.dash-notice {
  p {
    white-space: pre-line;
  }
}

.esri-ui-bottom-right .esri-expand .esri-legend {
  width: 210px;
}

.esri-legend__layer-caption {
  display: none;
}

.fa-m::before {
  font-family:'Courier New', Courier, monospace;
  content: 'M';
}

.region-cards {
  row-gap: 1em;
  align-items: stretch;
}

.docusign-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.react-select__control {
  color: #212121;
  box-shadow: inset 0 -1px 0 #ddd;
  &--is-focused {
    box-shadow: inset 0 -2px 0 #327D9B !important;
    border-color: transparent !important;
  }
}
