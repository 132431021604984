
$white: #fff;
$gray-75: #FCFCFC;
$gray-100: #f8f9fa;
$gray-200: #eee;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #bbb;
$gray-525: #888;
$gray-550: #888;
$gray-575: #747575;
$gray-600: #666;
$gray-700: #444;
$gray-800: #222;
$gray-900: #212121;
$black: #000;
$blue: #2196F3;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e51c23;
$orange: #fd7e14;
$yellow: #ff9800;
$green: #4CAF50;
$teal: #20c997;
$cyan: #9C27B0;
$primary: #327D9B;
$secondary: #A26807;
$success: #3A8540;
$info: #172545;
$warning: #543604;
$danger: #EA1B0B;
$light: rgb(249, 249, 248);
$dark: #003d6b;
$gray: #dbdbdb;
$body-color: $gray-700;
$font-family-sans-serif: "Myriad Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1rem;
$font-weight-base: 400;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;
$input-padding-y: 1rem;
$input-padding-x: 0;
$input-padding-y-sm: $font-size-base;
$input-padding-x-sm: 0;
$input-padding-y-lg: ($font-size-base * 1.25);
$input-padding-x-lg: 0;
$input-bg: transparent;
$input-disabled-bg: transparent;
$input-color: $gray-600;
$input-border-color: transparent;
$input-border-width: 0rem;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-placeholder-color: rgba(0,0,0,0.4);
$input-group-addon-bg: transparent;
$custom-control-gutter: 1.75rem;
$custom-control-indicator-focus-box-shadow: 0;
$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-bg: $white;
$custom-checkbox-indicator-border-radius: 2px;
$custom-control-indicator-disabled-bg: $gray-100;
$nav-link-disabled-color: $gray-500;
$nav-tabs-border-color: transparent;
$navbar-padding-y: 1rem;
$navbar-dark-color: rgba($white,.75);
$navbar-dark-hover-color: $white;
$card-border-width: 0;
$card-border-color: transparent;
$tooltip-bg: $gray-700;
$modal-content-border-color: transparent;
$progress-height: 0.375rem;
$progress-border-radius: 0;
$close-font-size: 2.125rem;
$close-font-weight: 300;

$grid-gutter-width: 10px;

$navbar-height: 5.75em;

$sizes: ("10": 10%);
